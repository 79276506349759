export const routes = {
  landingPage: {
    root: "/",
  },

  menu: {
    shortName: (shortName: string) => `/m/${shortName}`,
    qr: (id: string) => `/q/${id}`,
  },

  auth: {
    root: "/auth",
    login: "/auth/login",
    register: "/auth/register",
    forgotPassword: "/auth/forgot-password",
    verificationEmailSent: "/auth/verification-email-sent",
  },

  dashboard: {
    root: "/dashboard",
    editMenu: (id: string) => `/dashboard/menu/${id}`,
    addMenu: () => `/dashboard/menu/add`,
  },

  account: {
    billing: "/account/billing",
    settings: "/account/settings",
    support: "/account/support",
    resetPassword: "/account/reset-password",
  },

  return: {
    subscriptionReturn: "/return",
  },

  legal: {
    terms: "/terms",
    privacy: "/privacy",
  },
};
