import { notifications } from "@mantine/notifications";
import { z } from "zod";

import { routes } from "@/routes";
import { dateSchema } from "@/utils/date.utils";
import { assert } from "@qrbite/shared/utils/general.utils";
import { AuthError, Session, User, WeakPassword } from "@supabase/supabase-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import supabase from "../supabase";

export const LoginBodySchema = z.object({
  email: z.string().email(),
  password: z.string(),
  captchaToken: z.string().optional(),
});

export type LoginBody = z.infer<typeof LoginBodySchema>;

const LoginResponseSchema = z.object({
  type: z.literal("bearer"),
  token: z.string(),
  expiresAt: dateSchema,
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;

export const SignUpBodySchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  password: z.string().min(8),
  captchaToken: z.string().optional(),
});

export type SignUpBody = z.infer<typeof SignUpBodySchema>;

export const useSignUpWithEmail = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<
    {
      user: User | null;
      session: Session | null;
    },
    AuthError,
    z.infer<typeof SignUpBodySchema>
  >({
    mutationKey: ["auth", "signUpWithEmail"],
    mutationFn: async (variables) => {
      const { data, error } = await supabase.auth.signUp({
        email: variables.email,
        password: variables.password,
        options: {
          data: {
            first_name: variables.firstName,
            last_name: variables.lastName,
          },
          emailRedirectTo: `${window.location.origin}${routes.dashboard.root}`,
          ...(variables.captchaToken === undefined
            ? {}
            : { captchaToken: variables.captchaToken }),
        },
      });

      if (error) {
        throw error;
      }

      queryClient.invalidateQueries({ queryKey: ["account"] });

      assert(data.user !== null, "User is null");

      return data;
    },
    onSuccess: () => {
      notifications.show({
        title: "Verification email sent",
        message:
          "We have sent you an email with a link to verify your email address. Please check your inbox.",
      });

      navigate(routes.auth.verificationEmailSent);
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

export const useSignInWithGoogle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["auth", "signInWithGoogle"],
    mutationFn: async () => {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location.origin}${routes.dashboard.root}`,
        },
      });

      if (error) throw error;

      queryClient.invalidateQueries({ queryKey: ["account"] });
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

export const useSignInWithEmail = () => {
  const queryClient = useQueryClient();

  return useMutation<
    {
      user: User;
      session: Session;
      weakPassword?: WeakPassword;
    },
    AuthError,
    z.infer<typeof LoginBodySchema>
  >({
    mutationKey: ["auth", "signInWithEmail"],
    mutationFn: async (variables) => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: variables.email,
        password: variables.password,
        options:
          variables.captchaToken === undefined
            ? undefined
            : { captchaToken: variables.captchaToken },
      });

      if (error) {
        throw error;
      }

      queryClient.invalidateQueries({ queryKey: ["account"] });

      return data;
    },
    onSuccess: () => {
      notifications.show({
        title: "Welcome back!",
        message: "You have successfully logged in",
      });
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation<void, AuthError>({
    mutationKey: ["auth", "logout"],
    mutationFn: async () => {
      const { error } = await supabase.auth.signOut();

      if (error) {
        throw error;
      }

      queryClient.invalidateQueries({ queryKey: ["account"] });
    },
    onSuccess: () => {
      notifications.show({
        title: "Goodbye!",
        message: "You have successfully logged out",
      });
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
  });
};

export const SendResetPasswordLinkBodySchema = z.object({
  email: z.string().email(),
  captchaToken: z.string().optional(),
});

export type SendResetPasswordLinkBody = z.infer<
  typeof SendResetPasswordLinkBodySchema
>;

export const useSendResetPasswordLink = () => {
  return useMutation<void, AuthError, SendResetPasswordLinkBody>({
    mutationKey: ["auth", "send-reset-password-link"],
    mutationFn: async ({ email, captchaToken }) => {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        captchaToken,
        redirectTo: `${window.location.origin}${routes.dashboard.root}`,
      });

      if (error) throw error;
    },
    onError: (error) => {
      notifications.show({ message: error.message, color: "red" });
    },
    onSuccess: () => {
      notifications.show({
        title: "Reset email sent",
        message:
          "We have sent you an email with a link to reset your password. Please check your inbox.",
      });
    },
  });
};

const zUserMetadata = z.object({
  name: z.string().optional(),
  full_name: z.string().optional(),
  avatar_url: z.string().optional(),
  first_name: z.string().optional(),
  last_name: z.string().optional(),
});

export async function handleUpsertUserData(
  user: User,
  overrides?: {
    firstName?: string;
    lastName?: string;
    avatarUrl?: string;
  },
) {
  const { data: metadata } = zUserMetadata.safeParse(user.user_metadata);

  const { firstName, lastName } = parseNameFromMetadata(user, metadata);

  return supabase
    .from("User")
    .upsert({
      id: user.id,
      firstName: overrides?.firstName ?? firstName,
      lastName: overrides?.lastName ?? lastName,
      avatarUrl: overrides?.avatarUrl ?? metadata?.avatar_url,
    })
    .select()
    .single();
}

function parseNameFromMetadata(
  user: User,
  metadata: z.infer<typeof zUserMetadata> | undefined,
) {
  if (metadata?.first_name !== undefined && metadata.last_name !== undefined) {
    return {
      firstName: metadata.first_name,
      lastName: metadata.last_name,
    };
  }

  const fullName =
    metadata?.full_name ??
    metadata?.name ??
    user.email?.split("@")[0]?.replace(".", " ");

  return parseName(fullName);
}

function parseName(name: string | undefined) {
  if (name === undefined) {
    return {
      firstName: undefined,
      lastName: undefined,
    };
  }

  const str = name.trim();
  const spaceIndex = str.lastIndexOf(" ");

  if (spaceIndex === -1) {
    return {
      firstName: str,
      lastName: "",
    };
  }

  return {
    firstName: str.slice(0, spaceIndex),
    lastName: str.slice(spaceIndex + 1),
  };
}
